/* eslint-disable */
import * as Types from '../../../../../graphql/types.gen'

import { gql } from '@apollo/client/index.js'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type UpdateVenueProfileMutationVariables = Types.Exact<{
  updateVenueProfileOptions: Types.VenueProfileUpdateOptions
}>

export type UpdateVenueProfileMutation = {
  __typename?: 'Mutation'
  updateVenueProfile: {
    __typename?: 'ProfileUpdateResult'
    ok: boolean
    error?: {
      __typename?: 'ProfileUpdateError'
      code: Types.ProfileUpdateErrorCode
    } | null
  }
}

export const UpdateVenueProfileDocument = gql`
  mutation updateVenueProfile(
    $updateVenueProfileOptions: VenueProfileUpdateOptions!
  ) {
    updateVenueProfile(options: $updateVenueProfileOptions) {
      ok
      error {
        code
      }
    }
  }
`
export type UpdateVenueProfileMutationFn = Apollo.MutationFunction<
  UpdateVenueProfileMutation,
  UpdateVenueProfileMutationVariables
>

/**
 * __useUpdateVenueProfileMutation__
 *
 * To run a mutation, you first call `useUpdateVenueProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueProfileMutation, { data, loading, error }] = useUpdateVenueProfileMutation({
 *   variables: {
 *      updateVenueProfileOptions: // value for 'updateVenueProfileOptions'
 *   },
 * });
 */
export function useUpdateVenueProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVenueProfileMutation,
    UpdateVenueProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVenueProfileMutation,
    UpdateVenueProfileMutationVariables
  >(UpdateVenueProfileDocument, options)
}
export type UpdateVenueProfileMutationHookResult = ReturnType<
  typeof useUpdateVenueProfileMutation
>
export type UpdateVenueProfileMutationResult =
  Apollo.MutationResult<UpdateVenueProfileMutation>
export type UpdateVenueProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateVenueProfileMutation,
  UpdateVenueProfileMutationVariables
>
