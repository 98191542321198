/* eslint-disable */
import * as Types from '../../../../../graphql/types.gen'

import { gql } from '@apollo/client/index.js'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type UpdateArtistMutationVariables = Types.Exact<{
  updateArtistProfileOptions: Types.ArtistProfileUpdateOptions
}>

export type UpdateArtistMutation = {
  __typename?: 'Mutation'
  updateArtistProfile: {
    __typename?: 'ProfileUpdateResult'
    ok: boolean
    error?: {
      __typename?: 'ProfileUpdateError'
      code: Types.ProfileUpdateErrorCode
    } | null
  }
}

export const UpdateArtistDocument = gql`
  mutation updateArtist(
    $updateArtistProfileOptions: ArtistProfileUpdateOptions!
  ) {
    updateArtistProfile(options: $updateArtistProfileOptions) {
      ok
      error {
        code
      }
    }
  }
`
export type UpdateArtistMutationFn = Apollo.MutationFunction<
  UpdateArtistMutation,
  UpdateArtistMutationVariables
>

/**
 * __useUpdateArtistMutation__
 *
 * To run a mutation, you first call `useUpdateArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtistMutation, { data, loading, error }] = useUpdateArtistMutation({
 *   variables: {
 *      updateArtistProfileOptions: // value for 'updateArtistProfileOptions'
 *   },
 * });
 */
export function useUpdateArtistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArtistMutation,
    UpdateArtistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateArtistMutation,
    UpdateArtistMutationVariables
  >(UpdateArtistDocument, options)
}
export type UpdateArtistMutationHookResult = ReturnType<
  typeof useUpdateArtistMutation
>
export type UpdateArtistMutationResult =
  Apollo.MutationResult<UpdateArtistMutation>
export type UpdateArtistMutationOptions = Apollo.BaseMutationOptions<
  UpdateArtistMutation,
  UpdateArtistMutationVariables
>
